import * as THREE from 'three'

/**
 * Shader code for world-unit point cloud rendering
 */
export const WorldUnitPointShader = {
  vertexShader: () => `
    uniform float pointSize;
    uniform float scale;
    uniform float pixelRatio;
    uniform float usePerspective;
    
    attribute float size;
    attribute vec3 color;
    
    varying vec3 vColor;
    
    void main() {
      vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
      
      // Use per-point size if available, otherwise use default pointSize
      float finalSize = size > 0.0 ? size : pointSize;
      
      // Calculate size based on world units
      if (usePerspective > 0.5) {
        // Dynamic sizing that maintains world-space scale
        gl_PointSize = finalSize * (projectionMatrix[1][1] * scale) / length(mvPosition.xyz) * pixelRatio;
      } else {
        // Fixed world size regardless of distance
        gl_PointSize = finalSize * (projectionMatrix[1][1] * scale) * pixelRatio;
      }
      
      // Ensure minimum point size
      gl_PointSize = max(gl_PointSize, 1.0);
      
      gl_Position = projectionMatrix * mvPosition;
      
      // Pass color to fragment shader
      vColor = color.rgb;
    }
  `,

  fragmentShader: () => `
    uniform vec3 color;
    uniform sampler2D pointTexture;
    uniform bool useTexture;
    uniform bool circleShape;
    
    varying vec3 vColor;
    
    void main() {
      // Determine the color (attribute or uniform)
      vec3 finalColor = (vColor.r != 0.0 || vColor.g != 0.0 || vColor.b != 0.0) ? vColor : color;
      
      // Create circular points if enabled
      if (circleShape && length(gl_PointCoord - 0.5) > 0.5) {
        discard;
      }
      
      // Apply texture if available
      if (useTexture) {
        vec4 texColor = texture2D(pointTexture, gl_PointCoord);
        gl_FragColor = vec4(finalColor, 1.0) * texColor;
      } else {
        gl_FragColor = vec4(finalColor, 1.0);
      }
    }
  `
}

/**
 * Material for rendering point clouds with sizes in world units
 * similar to Unity's particle systems
 */
export default class WorldUnitPointCloudMaterial extends THREE.ShaderMaterial {
  /**
   * @param {Object} options - Configuration options
   * @param {THREE.Texture} options.texture - Optional texture for points
   * @param {Number} options.size - Default size in world units (default: 0.1)
   * @param {THREE.Color|String|Number} options.color - Default color (default: white)
   * @param {Boolean} options.usePerspective - Enable perspective scaling (default: true)
   * @param {Boolean} options.circleShape - Render points as circles (default: true)
   * @param {Object} params - Additional THREE.ShaderMaterial parameters
   */
  constructor(options = {}, params = {}) {
    // Initialize with basic parameters
    super(params);

    // Store material options
    this.pointSize = options.size !== undefined ? options.size : 0.1;
    this.pointColor = options.color !== undefined ? new THREE.Color(options.color) : new THREE.Color(0xffffff);
    this.pointTexture = options.texture || null;
    this.usePerspective = options.usePerspective !== undefined ? options.usePerspective : true;
    this.circleShape = options.circleShape !== undefined ? options.circleShape : true;

    // Set shader code
    this.vertexShader = WorldUnitPointShader.vertexShader();
    this.fragmentShader = WorldUnitPointShader.fragmentShader();
    this.name = 'WorldUnitPointCloudMaterial';

    // Configure material properties
    this.transparent = true;
    this.depthWrite = true;  // Enable depth writing
    this.depthTest = true;
    this.alphaTest = 0.5;    // Discard pixels with alpha < 0.5

    // Set uniforms
    this.uniforms = {
      // Size-related uniforms
      pointSize: { value: this.pointSize },
      scale: { value: window.innerHeight / 2 },
      pixelRatio: { value: window.devicePixelRatio || 1 },
      usePerspective: { value: this.usePerspective ? 1.0 : 0.0 },

      // Appearance uniforms
      color: { value: this.pointColor },
      pointTexture: { value: this.pointTexture },
      useTexture: { value: this.pointTexture !== null },
      circleShape: { value: this.circleShape }
    };
  }

  /**
   * Set the default point size in world units
   * @param {Number} size - Size in world units
   */
  setSize(size) {
    this.pointSize = size;
    this.uniforms.pointSize.value = size;
  }

  /**
   * Set the default point color
   * @param {THREE.Color|String|Number} color - Color for points
   */
  setColor(color) {
    this.pointColor = new THREE.Color(color);
    this.uniforms.color.value = this.pointColor;
  }

  /**
   * Enable or disable perspective scaling
   * @param {Boolean} enable - Whether to enable perspective scaling
   */
  setPerspective(enable) {
    this.usePerspective = enable;
    this.uniforms.usePerspective.value = enable ? 1.0 : 0.0;
  }

  /**
   * Set a texture for the points
   * @param {THREE.Texture} texture - Texture to use
   */
  setTexture(texture) {
    this.pointTexture = texture;
    this.uniforms.pointTexture.value = texture;
    this.uniforms.useTexture.value = texture !== null;
  }

  /**
   * Enable or disable circular point shape
   * @param {Boolean} enable - Whether to render points as circles
   */
  setCircleShape(enable) {
    this.circleShape = enable;
    this.uniforms.circleShape.value = enable;
  }

  /**
   * Update material when window is resized
   * Call this from your window resize handler
   */
  handleResize() {
    this.uniforms.scale.value = window.innerHeight / 2;
    this.uniforms.pixelRatio.value = window.devicePixelRatio || 1;
  }

  /**
   * Create a simple point cloud with this material
   * @param {Float32Array|Array} positions - Point positions [x,y,z,...]
   * @param {Float32Array|Array} colors - Optional point colors [r,g,b,...]
   * @param {Float32Array|Array} sizes - Optional point sizes
   * @return {THREE.Points} Points object to add to your scene
   */
  static createPointCloud(positions, colors = null, sizes = null) {
    // Create geometry
    const geometry = new THREE.BufferGeometry();

    // Set positions
    if (positions instanceof Float32Array) {
      geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    } else {
      geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    }

    // Set colors if provided
    if (colors) {
      if (colors instanceof Float32Array) {
        geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
      } else {
        geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
      }
    }

    // Set sizes if provided
    if (sizes) {
      if (sizes instanceof Float32Array) {
        geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));
      } else {
        geometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1));
      }
    }

    // Create material and points
    const material = new WorldUnitPointCloudMaterial();
    return new THREE.Points(geometry, material);
  }
}

// Example usage:
/*
import WorldUnitPointCloudMaterial from './WorldUnitPointCloudMaterial.js';

// Create a simple point cloud
const createPointCloud = () => {
  // Create random positions
  const pointCount = 1000;
  const positions = new Float32Array(pointCount * 3);
  const colors = new Float32Array(pointCount * 3);
  const sizes = new Float32Array(pointCount);
  
  for (let i = 0; i < pointCount; i++) {
    // Random position in a cube
    positions[i * 3] = (Math.random() - 0.5) * 10;
    positions[i * 3 + 1] = (Math.random() - 0.5) * 10;
    positions[i * 3 + 2] = (Math.random() - 0.5) * 10;
    
    // Random color
    colors[i * 3] = Math.random();
    colors[i * 3 + 1] = Math.random();
    colors[i * 3 + 2] = Math.random();
    
    // Random size
    sizes[i] = Math.random() * 0.2 + 0.05;
  }
  
  // Create geometry with attributes
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
  geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
  geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));
  
  // Create material with options
  const material = new WorldUnitPointCloudMaterial({
    size: 0.1,                      // Default size for points without size attribute
    color: 0xffffff,                // Default color for points without color attribute
    usePerspective: true,           // Enable perspective scaling
    circleShape: true,              // Render points as circles
    texture: null                   // No texture
  });
  
  // Create points and add to scene
  const points = new THREE.Points(geometry, material);
  scene.add(points);
  
  // Handle window resize
  window.addEventListener('resize', () => {
    material.handleResize();
  });
  
  return points;
};

// Alternative method using static helper
const pointCloud = WorldUnitPointCloudMaterial.createPointCloud(positions, colors, sizes);
scene.add(pointCloud);
*/