// Three.js version of the Partial Immersive Sphere Shader

function vertexShader() {
    return `
  varying vec2 vUv;
  
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`
}
function fragmentShader() {
    return `
  uniform sampler2D mainTexture;
  uniform float innerEdgeDistance;
  uniform float edgeWidth;
  
  varying vec2 vUv;
  
  // Function to calculate distance from the center of the texture
  float distToCenter(vec2 uv) {
    vec2 center = vec2(0.5, 0.5); // Center of the texture in UV space
    return length(uv - center);
  }
  
  void main() {
    vec4 color = texture2D(mainTexture, vUv);
    
    // Calculate distance to the center
    float dist = distToCenter(vUv);
    
    // Simple dithering pattern (same as the Unity shader)
    float ditherPattern = fract(sin(dot(vUv, vec2(12.9898, 78.233))) * 43758.5453);
    
    // Apply transparency based on distance and edge width
    if (dist > innerEdgeDistance) {
      if (dist > (innerEdgeDistance + edgeWidth)) {
        discard; // Equivalent to the Unity discard
      } else {
        // Fade out at the edge
        color.a *= clamp(1.0 - ((dist - innerEdgeDistance) / edgeWidth), 0.0, 1.0);
      }
    }
    
    gl_FragColor = color;
  }
`
}

export { vertexShader, fragmentShader }