import * as THREE from 'three'

import * as PartialImmersiveSphereShader from '@/assets/js/shaders/partial-immersive-sphere-shader.js'

export default class ImmersiveSphereMaterial extends THREE.ShaderMaterial {
  constructor(params, options) {
    // Default parameters
    super(params)
    this.vertexShader = PartialImmersiveSphereShader.vertexShader()
    this.fragmentShader = PartialImmersiveSphereShader.fragmentShader()
    this.name = 'PartialImmersiveSphereShader'

    this.transparent = true
    this.side = THREE.FrontSide // Render only the inner side

    this.texture = options.texture || null

    this.uniforms = {
      // Object uniforms
      mainTexture: { value: this.texture },
     
      // Number uniforms
      innerEdgeDistance: { value: options.innerEdgeDistance == null ? 0.5 : options.innerEdgeDistance },
      edgeWidth: { value: options.edgeWidth == null ? 0.1 : options.edgeWidth },
    }
  }
}
